<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <!-- Filtro por período -->
            <ul class="nav line-tabs borderless flex-center mb-5">
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'monthly'}" v-on:click="setTempPeriod('monthly')" ref="view_type_monthly"  data-toggle="pill" data-period="monthly">{{ $t('period.monthly') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'quarter'}" v-on:click="setTempPeriod('quarter')" ref="view_type_quarter"  data-toggle="pill" data-period="quarter">{{ $t('period.quarter') }}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{'active': filters.filPeriodo === 'yearly'}" v-on:click="setTempPeriod('yearly')"  ref="view_type_yearly" data-toggle="pill" data-period="yearly">{{ $t('period.yearly') }}</a>
              </li>
            </ul>
            <!-- Filtros -->
            <h5 class="mb-0">Filtrar por:</h5>
            <form id="report-goals-list-filter" v-on:submit.prevent="applyFilter()">

              <!-- Vendedor -->
              <!--<h6 class="mb-3 mt-5">{{ $t('form.report.seller') }}</h6>-->
              <div class="mt-5">
                  <div class="md-form mb-0 ">
                    <div class="md-form mb-0">
                      <select class="md-form-control" :value="filters.filEmployee" id="employee" name="employee" ref="employee">
                        <option v-for="(item, index) in sellerList" v-bind:key="index" :value="item.id"  :selected="filters.filEmployee === item.id" >{{ item.nome }}</option>
                      </select>
                      <label :class="{'active': true}">{{ $t('form.report.seller') }}</label>
                    </div>
                  </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <div class="row">
                <div class="col-md-12">
                  <div class="md-form mb-0 row">
                    <div class="col-md-6">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filYear" id="year" name="year" ref="year" >
                          <option v-for="(item, index) in $util.years(2021)" v-bind:key="index" :value="item" :selected="filters.filYear === item" >{{ item }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.year') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'quarter'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filQuarter" id="quarter" name="quarter" ref="quarter" >
                          <option v-for="(quarter, index) in $util.quarters()" v-bind:key="index" :value="index" :selected="filters.filQuarter === parseInt(index)" >{{ quarter }}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.quarter') }}</label>
                      </div>
                    </div>
                    <div class="col-md-6" v-if="tempPeriod === 'monthly'">
                      <div class="md-form mb-0">
                        <select class="md-form-control" :value="filters.filMonth" id="month" name="month" ref="month" >
                          <option v-for="(month, index) in $util.months()" v-bind:key="index" :value="index" :selected="filters.filMonth === parseInt(index)" >{{ month}}</option>
                        </select>
                        <label :class="{'active': true}">{{ $t('form.report.month') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}</button>
                    </div>
                    <div class="col-6">
                      <button type="button"  v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{ $t('filter.reset') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-10 mb-3 filter-container">
        <filter-container :activeFilterList="getActiveFilterList()" />
      </div>

      <div class="col-12">
        <button-back>
          AUDITORIA PROCESSOS
        </button-back>
      </div>

      <!-- Queries -->
      <card class-root="col-12" v-if="showQueries">
        <div class="row">
          <div class="col-12 mb-5" v-for="(query, index) in reportData.queries" v-bind:key="index">
            <div class="title">{{ index.toUpperCase() }}</div>
            <div >
              <pre>{{ query.sql }}</pre>
              <table class="table table-striped" v-if="query.params">
                <thead>
                    <tr>
                  <td>Nome</td>
                  <td>Valor</td>
                    </tr>
                </thead>
                <tr v-for="(param, index) in query.params" :key="index">
                  <td>{{ param.name }}</td>
                  <td>{{ param.value }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </card>

      <!-- Vendedor -->
      <card class-root="col-12">
        <div class="flexbox mb-4 mt-3">
          <div  v-if="isSuperAdmin" v-on:click="toogleQueries" class="query-info"><i class="fa fa-question-circle font-20"></i><md-tooltip md-direction="right">Visualizar queries</md-tooltip></div>
          <div class="media">
              <span class="position-relative d-inline-block mr-4" v-if="reportData.vendedor.imagem">
                <img class="rounded-circle"
                     onerror="this.src='https://www.royalcargo.com.br/assinatura.royalcargo.com.br/temporario/apresentacao/fun/default.png';"
                     :src="reportData.vendedor.imagem"
                     alt="image" width="100">
              </span>
            <div class="media-body">
              <div class="h4">{{ reportData.vendedor.apelido ? reportData.vendedor.apelido : reportData.vendedor.nome }}</div>
              <div class="text-muted">{{ reportData.vendedor.cargo }}</div>
            </div>
          </div>
        </div>
      </card>

      <!-- Comissão -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="totalComissao + totalComissaoMBS"
                 :hide-goal="true"
                 :black="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
                 prefix="R$"
      >
        <template slot="title">
          Total Comissão
        </template>
      </card-goal>

      <!-- LUCRO -->
      <card-goal class-root="col-12 col-md-3 mb-4"
          :realizado="totalComissao"
          :hide-goal="true"
          :decimals="2"
          :meta="0"
          :label-realizado="''"
          prefix="R$"
          >
        <template slot="title">
          Comissão
        </template>
      </card-goal>

      <!-- LUCRO MBS -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="totalComissaoMBS"
                 :hide-goal="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
                 prefix="R$"
      >
        <template slot="title">
          Comissão MBS
        </template>
      </card-goal>

      <!-- PROCESSOS -->
      <card-goal class-root="col-12 col-md-3 mb-4"
                 :realizado="totalProcessos"
                 :hide-goal="true"
                 :decimals="2"
                 :meta="0"
                 :label-realizado="''"
      >
        <template slot="title">
          Processos
        </template>
      </card-goal>

      <card class-root="col-12 mt-2" >
        <div class="row">
          <div class="col-12 mb-5">
            <local-data-vue-table
              track-by="numero_processo"
              :filter-fields="filterFields"
              :total-fields="totalFields"
              :per-page="20" :fields="fieldsTable"
              :data="reportData.processos"
              trackBy="numero_processo" no-data-template="Nenhum processo no período." />
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Components
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Card from '@/views/Report/Seller/2022/Card'
import CardGoal from '@/views/Report/Seller/2022/CardGoal'
import ButtonBack from '@/components/Navigate/ButtonBack'
import LocalDataVueTable from '@/components/Table/LocalDataVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// DatePicker
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Services
import CustomerService from '@/services/CustomerService'
import EmployeeService from '@/services/EmployeeService'
import ReportService from '@/services/ReportService'

// import SolidDonutChart from '@/components/Chart/amcharts4/SolidDonutChart'

export default {
  name: 'AuditProcesses',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('report.audit.title') + ' ' + this.$i18n.t('report.audit.processos.title') + ' - %s'
    }
  },
  data () {
    return {
      showQueries: false,
      valor: 10.0,
      customerList: [],
      sellerList: [],
      tempPeriod: 'monthly',
      seller: {},
      reportData: {
        processos: {
          data: []
        },
        vendedor: {
          id: null,
          nome: null,
          apelido: null,
          cargo: null,
          imagem: null
        },
        volume: {
          aereo: {
            realizado: 0,
            meta: 0
          },
          im: {
            realizado: 0,
            meta: 0
          },
          em: {
            realizado: 0,
            meta: 0
          },
          cabotagem: {
            realizado: 0,
            meta: 0
          },
          lcl: {
            realizado: 0,
            meta: 0
          },
          nr: {
            realizado: 0,
            meta: 0
          },
          intr: {
            realizado: 0,
            meta: 0
          },
          seguro: {
            n_total: 0,
            n_realizados: 0,
            realizado: 0,
            is_percent: true,
            meta: 0
          }
        }
      },
      isLoading: true,
      fullPage: true,
      activeFilterList: {
        employee: null,
        year: null,
        month: null
      },
      firstFilter: true,
      filter: {}
    }
  },
  components: {
    LocalDataVueTable,
    Card,
    CardGoal,
    FilterSideBar,
    Loading,
    FilterContainer,
    ButtonBack
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    if (this.user) {
      this.getReportSellerData()
    }
    this.$jQuery('#begin_date, #end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    toogleQueries () {
      this.showQueries = !this.showQueries
    },
    tableHeaders () {
      let headers = [
        {
          title: 'Q1',
          period: 1
        },
        {
          title: 'Q2',
          period: 2
        },
        {
          title: 'Q3',
          period: 3
        },
        {
          title: 'Q4',
          period: 4
        }
      ]

      if (this.filters.filPeriodo === 'monthly') {
        headers = []
        for (let i = 1; i <= 12; i++) {
          headers.push(
            {
              title: this.$i18n.t('form.goal.idFrequency.monthly.' + i),
              period: i
            }
          )
        }
      }

      return headers
    },
    getValueByPeriod (indicator, attribute, filters, period) {
      let type = filters.filPeriodo
      let value = 0

      if (type === 'monthly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]

          if (period === item.month) {
            value = item[attribute]
            break
          }
        }
      } else if (type === 'quarter') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          let quarter = parseInt(Math.ceil((item.month) / 3))
          if (period === quarter) {
            value += item[attribute]
          }
        }
      } else if (type === 'yearly') {
        for (let i in indicator.goalPerMonth) {
          let item = indicator.goalPerMonth[i]
          value += item[attribute]
        }
      }

      return this.numberFormat(value, 0, ',', '.')
    },
    getActiveFilterList () {
      let activeFilterList = {
        period: null,
        employee: null,
        year: null
      }

      if (this.filters.filPeriodo === 'quarter') {
        activeFilterList.quarter = null
      } else if (this.filters.filPeriodo === 'monthly') {
        activeFilterList.month = null
      }

      return activeFilterList
    },
    getTitle () {
      let title = ''

      if (this.filters.filPeriodo === 'monthly') {
        title = 'RESULTADO MENSAL'
      } else if (this.filters.filPeriodo === 'quarter') {
        title = 'RESULTADO TRIMESTRAL'
      } else if (this.filters.filPeriodo === 'yearly') {
        title = 'RESULTADO ANUAL'
      }

      return title
    },
    setTempPeriod (item) {
      this.tempPeriod = item
    },
    getPercentMark (value) {
      let goalOnPeriod = 1
      let percentMark = 100

      if (value.goal > 0) {
        goalOnPeriod = value.goal * 1.0
      }
      percentMark = (value.goal * 100 / goalOnPeriod)

      return percentMark
    },
    getQuarter (date) {
      let quarter = parseInt(Math.ceil((date.getMonth() + 1) / 3))

      return quarter
    },
    getPercentualInsuranceGoal (totalFiles, percent) {
      let totalPercent = 0

      if (totalFiles) {
        totalPercent = totalFiles * percent / 100
      }

      return totalPercent
    },
    buildFilters () {
      if (['monthly', 'quarter', 'yearly'].indexOf(this.filters.filPeriodo) === -1) {
        this.filters.filPeriodo = 'monthly'
      }

      this.filter.period = this.filters.filPeriodo
      this.tempPeriod = this.filters.filPeriodo

      let today = new Date()
      /**
       *  Não primeira abertura seta o filtros com o padrão
       *   - Semestre: Atual
       *   - Ano: Atual
       *   - Colaborador: Usuário autenticado
       *  */
      if (!this.filters.filYear) {
        this.filters.filYear = today.getFullYear()
      }

      if ((!this.filters.filQuarter)) {
        this.filters.filQuarter = this.getQuarter(today)
      }

      if (!this.filters.filMonth) {
        this.filters.filMonth = today.getMonth() + 1
      }

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // Senão filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id
      }

      this.filter.employee = this.filters.filEmployee
      this.filter.year = this.filters.filYear
      this.filter.quarter = this.filters.filQuarter
      this.filter.month = this.filters.filMonth

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    // Filter
    applyFilter () {
      // Period
      if (this.$refs.view_type_quarter.classList.contains('active')) {
        this.filters.filPeriodo = 'quarter'
      } else if (this.$refs.view_type_monthly.classList.contains('active')) {
        this.filters.filPeriodo = 'monthly'
      } else if (this.$refs.view_type_yearly.classList.contains('active')) {
        this.filters.filPeriodo = 'yearly'
      }

      this.filters.filEmployee = this.$refs.employee ? parseInt(this.$refs.employee.value) : null
      this.filters.filYear = this.$refs.year ? parseInt(this.$refs.year.value) : null
      this.filters.filQuarter = this.$refs.quarter ? parseInt(this.$refs.quarter.value) : null
      this.filters.filMonth = this.$refs.month ? parseInt(this.$refs.month.value) : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()

      /* Todo: Reload Charts */
      this.getReportSellerData()
    },
    resetFilter (event) {
      this.hideQuichSidebar()
      // hasFilter: false
      let today = new Date()

      this.filters.filYear = today.getFullYear()
      this.filters.filQuarter = this.getQuarter(today)
      this.filters.filMonth = today.getMonth() + 1
      this.filters.filPeriodo = 'monthly'

      if ((!this.filters.filEmployee || this.filters.filEmployee.length <= 0)) {
        // filtro pelo primeiro vendedor da lista
        this.filters.filEmployee = this.sellerList[0].id
      }

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.getReportSellerData()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    numberFormat (number, decimals, decPoint, thousandsSep) {
      return util.numberFormat(number, decimals, decPoint, thousandsSep)
    },
    showGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showNumber(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showNumber (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','

      if (thousandsSep !== '') {
        thousandsSep = thousandsSep || '.'
      }

      // eslint-disable-next-line
      if (number != parseInt(number)) {
        decimals = 2
      }

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    showIntegerGoal (number, thousandsSep, defaultValue) {
      if (number && number > 0) {
        return this.showInteger(number, thousandsSep, defaultValue)
      }

      return defaultValue
    },
    showInteger (number, thousandsSep, defaultValue) {
      defaultValue = (defaultValue !== undefined) ? defaultValue : 0
      let decimals = 0
      let decPoint = ','
      thousandsSep = thousandsSep || '.'

      if (number) {
        return this.numberFormat(number, decimals, decPoint, thousandsSep)
      }

      return defaultValue
    },
    // End Filter
    getReportSellerData () {
      let me = this
      this.isLoading = true
      if (this.$store.getters.userInfo) {
        EmployeeService.getSellers({ per_page: 1000 }).then(res => {
          me.sellerList = res.data.data

          me.buildFilters()
          ReportService.getSellerAudit(this.filter.employee, this.filter).then(res => {
            me.seller = res.data.seller
            me.reportData = res.data
            this.isLoading = false
          }).finally(function () {
            me.isLoading = false
          })
        })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      let filters = {
        'searchTerm': search
      }
      CustomerService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
      })
    },
    /**
     * DataTransform to display Quarter Progresss Chart Bar
     *
     * @param data  {Array}   unformatted chart data
     */
    barChartTransform (data) {
      let transformedGoal = data.map(x => (
        x.goal
        // x.total_goal
      ))

      let transformedRealized = data.map(x => (
        x.realized
        // x.total_realized
      ))

      let colors = []

      for (let i = 0; i < transformedRealized.length; i++) {
        if (transformedRealized[i] < transformedGoal[i]) {
          colors.push('#FFD863')
        } else {
          colors.push('#008f59')
        }
      }

      return [
        {
          data: transformedGoal,
          type: 'line',
          fill: false,
          borderWidth: 2,
          borderColor: '#84754E',
          backgroundColor: '#84754E'
        },
        {
          data: transformedRealized,
          fill: false,
          backgroundColor: colors
        }
      ]
    }
  },
  computed: {
    totalProcessos () {
      return this.reportData.processos.data.length
    },
    totalComissao () {
      let processesLength = this.reportData.processos.data.length
      if (processesLength < 1) return

      let total = 0.0

      for (let i = 0; i < processesLength; i++) {
        let processo = this.reportData.processos.data[i]
        if (processo.mbs && processo.contratou_rod_nac === false) {
          continue
        }
        total += parseFloat(processo.comissao_estimada)
      }

      return total
    },
    totalComissaoMBS () {
      let processesLength = this.reportData.processos.data.length
      if (processesLength < 1) return

      let total = 0.0

      for (let i = 0; i < processesLength; i++) {
        let processo = this.reportData.processos.data[i]
        if (processo.mbs && processo.contratou_rod_nac === false) {
          total += parseFloat(processo.comissao_estimada)
        }
      }

      return total
    },
    fieldsTable () {
      return [
        {
          name: 'numero_processo',
          title: 'Número Processo',
          sortField: 'numero_processo',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'mbs',
          title: 'MBS',
          sortField: 'mbs',
          formatter: (value) => this.$util.formatBoolean(value, true)
        },
        {
          name: 'contratou_rod_nac',
          title: 'Contr. Rod. Nac,',
          sortField: 'contratou_rod_nac',
          formatter: (value) => this.$util.formatBoolean(value, true)
        },
        {
          name: 'regra_comissao',
          title: 'Regra',
          sortField: 'regra_comissao',
          formatter: (value) => this.$util.formatRegraComissao(value)
        },
        {
          name: 'lucro_bruto_recebido',
          title: 'Lucro Estimado',
          sortField: 'lucro_bruto_recebido',
          formatter: (value) => this.$util.formatNumber(value, 2, ',', '.', ' '),
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'comissao_estimada',
          title: 'Comissão Estimada',
          sortField: 'comissao_estimada',
          formatter: (value) => this.$util.formatNumber(value),
          dataClass: 'text-center',
          titleClass: 'text-center'
        }
      ]
    },
    filterFields () {
      return [
        {
          name: 'numero_processo',
          title: 'Número Processo',
          type: 'text',
          value: null
        },
        {
          name: 'regra_comissao',
          title: 'Regra',
          type: 'options',
          options: {
            '': '',
            'mbs_3_por_cento': 'MBS 3%',
            'comissao_faixa': 'Faixa'
          },
          value: null
        },
        {
          name: 'mbs',
          title: 'MBS',
          type: 'boolean',
          value: null
        },
        {
          name: 'contratou_rod_nac',
          title: 'Contr. Rod. Nac,',
          type: 'boolean',
          value: null
        }
      ]
    },
    totalFields () {
      return [
        {
          name: 'comissao_estimada',
          formatter: (value) => this.$util.formatNumber(value)
        }
      ]
    },
    showNewChart () {
      if (this.reportData.summary && this.reportData.ano < 2021) {
        return false
      }
      return true
    },
    isDirector () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_DIRECTOR') !== -1) {
        return true
      }
      return false
    },
    isFinancial () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_FINANTIAL') !== -1) {
        return true
      }
      return false
    },
    isComercialAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_COMERCIAL_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isSeller () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        if (userInfo.active_employee) {
          if (userInfo.active_employee.is_seller) {
            return true
          }
        }
      }
      return false
    },
    showEmployeeFilter () {
      if (this.user) {
        let showEmployee = false
        if (this.user.roles.filter(role => role === 'ROLE_DIRECTOR').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SUPER_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_FINANTIAL').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_COMERCIAL_ADMIN').length > 0) {
          showEmployee = true
        } else if (this.user.roles.filter(role => role === 'ROLE_SALES_COORDINATOR').length > 0) {
          showEmployee = true
        }

        return showEmployee
      } else {
        return false
      }
    },
    totalFiles () {
      let total = 0

      let reportData = this.reportData
      if (reportData.details) {
        total = (reportData.details.fcl.import.boarded.files.current +
        reportData.details.fcl.export.boarded.files.current +
        reportData.summary.air.current +
        reportData.summary.maritime.lcl.current)
      }

      return total
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      this.getReportSellerData()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  .subtitle {
    margin-top: 3.0rem;
    margin-bottom: 0.8rem;
  }

  .query-info {
    position: absolute;
    top: 26px;
    right: 36px;
  }
</style>
