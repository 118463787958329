<template>
  <div class="row">
    <div class="col col-12" v-if="showFilter" >
        <div class="">
          <h5 class="box-title text-primary">FILTROS</h5>
          <form action="javascript:;">
            <div class="row">
              <div  v-for="(field, index) in filterFields" v-bind:key="index" class="pl-0 col-sm-4 form-group mb-4">
                <label class="active">{{ field.title }}</label>
                <input v-if="field.type === 'text'" class="form-control"
                   type="text"
                   :name="field.name"
                   v-model="filterFields[index].value"
                   v-on:input="updateValue"
                   :placeholder="field.title">

                <select v-else-if="field.type === 'boolean'" class="form-control" v-model="field.value">
                  <option value=""></option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>

                <select v-else-if="field.type === 'options'" class="form-control" v-model="field.value">
                  <option v-for="(option, index) in field.options" v-bind:key="index"
                          :value="index">
                    {{ option }}
                  </option>

                </select>

              </div>
            </div>
            <div class="form-group pull-right">
              <button class="btn btn-link" type="reset" v-on:click="onResetFilter"> Limpar filtros</button>
              <button class="btn btn-primary mr-2"  v-on:click="onFilter" type="submit">Filtrar</button>
            </div>
          </form>
        </div>
    </div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <label>{{$t(label)}}</label>
    <div class="table-responsive">
      <vuetable ref="vuetable"
                :noDataTemplate="this.$i18n.t(noDataTemplate)"
                :api-mode="false"
                :row-class="rowClass"
                :data-manager="dataManager"
                :fields="fields"
                :sort-order="sortOrder"
                :css="css.table"
                pagination-path="pagination"
                :per-page="perPage"
                :multi-sort="multiSort"
                :multi-sort-key="multiSortKey"
                :track-by="trackBy"
                @vuetable:pagination-data="onPaginationData"
                @vuetable:loading="onLoading"
                @vuetable:loaded="onLoaded"
                @vuetable:load-error="handleLoadError"
                @vuetable:cell-clicked="onRowClicked">
                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
      </vuetable>
      <div style="padding-top:10px">
        <vuetable-pagination ref="pagination"
                             @vuetable-pagination:change-page="onChangePage"
        >
        </vuetable-pagination>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
<!--        <div class="col-md-6">-->
<!--          Mostrando {{ showNumber(paginationData.from) }} até {{ showNumber(getToNumber) }} de {{ showNumber(paginationData.total) }}-->
<!--        </div>-->
        <div class="col-md-6">
          <vuetable-pagination ref="pagination"
                               :css="css.pagination"
                               @vuetable-pagination:change-page="onChangePage"
          ></vuetable-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { util } from '@/utils/utils.js'
import _ from 'lodash'

import Vuetable, { VuetablePagination } from 'vuetable-2'
// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import { totalmem } from 'os';
export default {
  name: 'LocalVueTable',
  data () {
    return {
      fullPage: true,
      isLoading: false,
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      paginationData: {
        total: 1,
        per_page: 2,
        current_page: 1,
        last_page: 10,
        from: 1,
        to: 10
      }
    }
  },
  props: {
    totalFields: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    noDataTemplate: {
      type: String,
      default: 'noting to result'
    },
    label: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          data: []
        }
      }
    },
    // Vue Table config
    filterFields: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    fields: {
      type: Array,
      required: true
    },
    sortOrder: {
      type: Array,
      default () {
        return []
      }
    },
    perPage: {
      type: Number,
      default: 20
    },
    multiSort: {
      type: Boolean,
      default: true
    },
    /*
     * physical key that will trigger multi-sort option
     * possible values: 'alt', 'ctrl', 'meta', 'shift'
     * 'ctrl' might not work as expected on Mac
     */
    multiSortKey: {
      type: String,
      default: 'shift'
    },
    trackBy: {
      type: String,
      default: 'id'
    }
    // End Vue Table config
  },
  components: {
    Vuetable,
    VuetablePagination,
    Loading
  },
  methods: {
    rowClass (item, type) {
      if (item !== undefined) {
        let className = ''
        if (item.hasOwnProperty('isTotal') && item.isTotal) {
          className = 'total'
        }
        return className
      }

      return ''
    },
    onFilter () {
      this.$refs.vuetable.refresh()
    },
    onResetFilter () {
      for (let i = 0; i < this.filterFields.length; i++) {
        this.filterFields[i].value = null
      }
      this.$refs.vuetable.refresh()
    },
    updateValue (event) {
      this.$emit('input', { ...this.filterFields })
    },
    dataManager (sortOrder, pagination) {
      let local = this.data.data

      for (let i = 0; i < this.filterFields.length; i++) {
        let filterItem = this.filterFields[i]

        if (filterItem.value !== null) {
          local = local.filter((process) => {
            if (filterItem.type === 'text') {
              let searchStringList = filterItem.value.split(',')
              for (let item in searchStringList) {
                let searchString = searchStringList[item].toLowerCase().trim()

                if (process[filterItem['name']].toLowerCase().indexOf(searchString) > -1) {
                  return true
                }
              }
            } else if (filterItem.type === 'boolean') {
              let boolValue = (filterItem.value === 'true')
              return process[filterItem['name']] === boolValue
            } else if (filterItem.type === 'options') {
              return process[filterItem['name']] === filterItem.value
            }
          })
        }
      }

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        )
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      )

      let from = pagination.from - 1
      let to = from + this.perPage

      let tableRows = _.slice(local, from, to)

      // Adiciona totalizadores
      if (this.totalFields.length > 0 && tableRows.length > 0) {
        let totalRow = Object.assign({}, tableRows[0])

        for (let attribute in totalRow) {
          totalRow[attribute] = null
        }

        totalRow.isTotal = true
        let firstColunm = this.fields[0]
        totalRow[firstColunm['name']] = 'Total'

        for (let index in this.totalFields) {
          let totalField = this.totalFields[index]

          let total = local.reduce(function (a, b) {
            return a + parseFloat(b[totalField['name']])
          }, 0)

          totalRow[totalField['name']] = total
        }

        tableRows.push(totalRow)
      }

      return {
        pagination: pagination,
        data: tableRows
      }
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.$emit('input', { ...this.data })
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false

      // this.$jQuery('table.table').reflowTable()
      if (this.$route.query.process) {
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.onRowClicked({ data: this.$refs.vuetable.$data.tableData[0] })
          }
        }
      }
    },
    onRowClicked (row) {
      this.showProcessDetails(row.data)
    },
    handleLoadError (response) {
    },
    // End Load Contact Tables
    // Format Table functions
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    // End Format Table functions
    refresh () {
      this.$refs.vuetable.refresh()
    }
  },
  watch: {
    data () {
      this.$refs.vuetable.refresh()
    }
  },
  computed: {
    showFilter () {
      return this.filterFields.length > 0
    },
    getToNumber () {
      if (this.paginationData.to > this.paginationData.total) {
        return this.paginationData.total
      }

      return this.paginationData.to
    },
    getHttpOptions () {
      let _this = this

      // If passed use passed params
      if (_this.httpOptions) {
        return _this.httpOptions
      }

      // Else use default http options
      return {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      }
    }
  }
}
</script>

<style scoped>
  .table-responsive {
    padding: 0px;
  }

  .table-responsive {
    max-height: none !important;
    overflow: hidden;
    overflow-x: auto;
  }
</style>

<style>
  tr.total {
    border-top: 2px solid #DEE2E6;
  }
 tr.total td {
   font-size: 0.80rem;
   font-weight: 600;
   color: 3f3f3f;
 }
</style>
